export const VALID_GUESSES = [
  'ындыы',
  'көһүө',
  'бэрин',
  'сатан',
  'киспэ',
  'киэҥэ',
  'дьүүл',
  'даача',
  'аарах',
  'күрэҥ',
  'муһун',
  'уурат',
  'стиль',
  'сааһы',
  'ситис',
  'сырыт',
  'кытар',
  'куоһа',
  'сатаа',
  'булуу',
  'кууча',
  'тигис',
  'кулан',
  'буһуу',
  'торҕо',
  'өйдөс',
  'роман',
  'карта',
  'буһар',
  'тайаа',
  'баҕар',
  'бэлэм',
  'район',
  'уктаа',
  'охтор',
  'тэрий',
  'балый',
  'арыгы',
  'тылын',
  'табыс',
  'тэбиҥ',
  'кэҕэй',
  'көмөр',
  'кэҥээ',
  'түрмэ',
  'киоск',
  'сэрэт',
  'бэриҥ',
  'көһүн',
  'барыл',
  'бүргэ',
  'күдүө',
  'ааҥыл',
  'көтүр',
  'кубок',
  'көмүк',
  'туһан',
  'көкөт',
  'сүтэр',
  'атаан',
  'кутук',
  'олуур',
  'куода',
  'кыһый',
  'магма',
  'бөтөс',
  'быһын',
  'көбүө',
  'эбиэс',
  'таһыы',
  'чукча',
  'кычам',
  'былах',
  'соһуй',
  'тутаа',
  'бүрүй',
  'кыбыт',
  'калап',
  'ыллан',
  'чаакы',
  'долуо',
  'буоҕу',
  'хатын',
  'көтүт',
  'тутун',
  'рынок',
  'бырах',
  'хааһы',
  'кудук',
  'хороо',
  'көҕөл',
  'кэрии',
  'тоһуй',
  'ыттыы',
  'ааҕыы',
  'баһый',
  'хотор',
  'хайыс',
  'оргут',
  'өйдөө',
  'акула',
  'кукаа',
  'сүүрт',
  'кэһэй',
  'тиһик',
  'ичээн',
  'эбээн',
  'охсуу',
  'элбэт',
  'кутуу',
  'хорут',
  'күтүө',
  'өтүүк',
  'элбээ',
  'салай',
  'чэрчи',
  'киэли',
  'харда',
  'ыарый',
  'өрөҕө',
  'уулун',
  'миинэ',
  'кэрэх',
  'уурай',
  'көтөх',
  'боҕуу',
  'туруу',
  'клоун',
  'үрдээ',
  'күдэх',
  'күргэ',
  'этчит',
  'драма',
  'түһүү',
  'сүргэ',
  'көҕөр',
  'кыһам',
  'куурт',
  'ыраат',
  'орган',
  'төнүн',
  'тирээ',
  'үөрэн',
  'далда',
  'былас',
  'тарыҥ',
  'көрүү',
  'дириҥ',
  'үүнүү',
  'омурт',
  'холбо',
  'быыра',
  'уоспа',
  'уунуу',
  'осман',
  'эргий',
  'хахха',
  'аатам',
  'сарыы',
  'төрөт',
  'батыс',
  'этика',
  'күбүү',
  'сиигэ',
  'көҕөн',
  'төһүү',
  'тирэн',
  'үксэт',
  'табыл',
  'кунус',
  'уллуҥ',
  'үөрэт',
  'чугуй',
  'бөҕөс',
  'төбөт',
  'гобой',
  'кылыс',
  'билир',
  'умнас',
  'дуоҕа',
  'таҕыс',
  'собор',
  'күөрэ',
  'искэҥ',
  'алгый',
  'наскы',
  'хойуу',
  'мыыла',
  'айаан',
  'бобуу',
  'раунд',
  'түмүү',
  'туспа',
  'дэпсэ',
  'аанай',
  'кэһэт',
  'кэтэс',
  'бокса',
  'тэһий',
  'конус',
  'инньэ',
  'быһыт',
  'илэчи',
  'күкүр',
  'бэдик',
  'толуй',
  'дьаар',
  'түһэр',
  'булун',
  'көлөт',
  'түмэн',
  'энньэ',
  'аллай',
  'соргу',
  'быйыт',
  'кэриэ',
  'сүтүү',
  'кэлин',
  'сэлии',
  'айбар',
  'хайыт',
  'дапсы',
  'ыллар',
  'тонна',
  'кэрэй',
  'сүрүн',
  'хатат',
  'уруок',
  'ринга',
  'эриэн',
  'саары',
  'үксээ',
  'умнуу',
  'суруй',
  'балыс',
  'удьаа',
  'кулун',
  'аалык',
  'үөҕүс',
  'үөдүй',
  'тэйит',
  'ыллаа',
  'дьаах',
  'санат',
  'араар',
  'киинэ',
  'крона',
  'иэһэр',
  'бөҕөх',
  'холуй',
  'сылай',
  'табах',
  'сылый',
  'аһыҥа',
  'алтах',
  'күдэн',
  'ирдэн',
  'тутул',
  'дүҥүр',
  'кутан',
  'аччат',
  'туман',
  'тамах',
  'тараа',
  'көппө',
  'саһар',
  'эмтээ',
  'аарык',
  'опаал',
  'көҕүл',
  'даркы',
  'бэрик',
  'силгэ',
  'хакас',
  'бүрүө',
  'көҕүс',
  'суума',
  'өлбүт',
  'тоҕой',
  'хомот',
  'поэма',
  'санан',
  'балта',
  'билим',
  'наада',
  'пиибэ',
  'тулун',
  'үрдүк',
  'уорба',
  'хотуу',
  'ньүөл',
  'албан',
  'истээ',
  'кырса',
  'аччаа',
  'обком',
  'дүөдэ',
  'салын',
  'чочуй',
  'үллэр',
  'далай',
  'барча',
  'кыһай',
  'муода',
  'ыаҕас',
  'хатас',
  'кичэл',
  'аҥаар',
  'муоһа',
  'өйдөн',
  'муора',
  'туора',
  'нуора',
  'уурун',
  'дьаҕа',
  'бүтүү',
  'тутус',
  'илээт',
  'искэн',
  'сыбаа',
  'турок',
  'астан',
  'кыһыт',
  'көбүт',
  'өлгөм',
  'хайаа',
  'баата',
  'хатыы',
  'ууһаа',
  'турун',
  'унаар',
  'бүтэй',
  'оттоо',
  'аҥнах',
  'кытын',
  'кэтит',
  'көтөр',
  'кэккэ',
  'көһүү',
  'күһэй',
  'кытай',
  'дьуос',
  'иирбэ',
  'киилэ',
  'сэрэй',
  'ыытыы',
  'калий',
  'көҥкө',
  'күтэр',
  'тирит',
  'көрүн',
  'түмэт',
  'спорт',
  'дэлэй',
  'ыксаа',
  'хинди',
  'аарпа',
  'дьиэк',
  'эргит',
  'төлөн',
  'өлөрт',
  'ньуур',
  'адаар',
  'толук',
  'кунан',
  'иилээ',
  'айаҕа',
  'чиҥэт',
  'төрөө',
  'чулку',
  'сүһүр',
  'иһэрт',
  'хобуо',
  'көйдү',
  'күүрт',
  'саҥар',
  'көпсө',
  'посол',
  'кэтээ',
  'паара',
  'тоҥор',
  'дэгэт',
  'холоо',
  'оргуй',
  'ымыйа',
  'кэриҥ',
  'тэбээ',
  'турба',
  'дууса',
  'даппы',
  'көһөр',
  'сыбах',
  'нэмин',
  'сурут',
  'чиҥээ',
  'атчыт',
  'ыстан',
  'кичэй',
  'дайды',
  'хатан',
  'сыана',
  'күлүм',
  'ситии',
  'баран',
  'сарын',
  'наара',
  'баара',
  'үргээ',
  'көтөт',
  'ыараа',
  'алтай',
  'атаар',
  'төлөө',
  'көтүү',
  'даата',
  'билин',
  'ыһаар',
  'ачаах',
  'сэрэн',
  'дьара',
  'оҥкул',
  'бадьа',
  'тэний',
  'бараа',
  'киэҕэ',
  'почта',
  'көрөр',
  'искэх',
  'тулуй',
  'буола',
  'тигин',
  'тубус',
  'кэҥэт',
  'ирдээ',
  'тойот',
  'сурун',
  'илбии',
  'ачыкы',
  'көйүк',
  'боруу',
  'сытый',
  'бурут',
  'ойоос',
  'курҕа',
  'күндэ',
  'куойа',
  'дьуот',
  'пуонд',
  'мэлий',
  'ааҕыс',
  'кэлии',
  'кутаа',
  'атара',
  'ыйыыр',
  'көбдө',
  'диэки',
  'эпоха',
  'быркы',
  'аҕаан',
  'уһаар',
  'хомой',
  'грамм',
  'түбэс',
  'хааха',
  'бэрис',
  'чаҕар',
  'ыҥыыр',
  'коран',
  'карат',
  'румын',
  'иҥэһэ',
  'тапта',
  'истии',
  'ааргы',
  'көрүк',
  'ньаат',
  'билис',
  'булуҥ',
  'кимии',
  'арбах',
  'тэбис',
  'гидра',
  'аттар',
  'ньиэп',
  'барыс',
  'ордор',
  'эргин',
  'тэрин',
  'түүрк',
  'түҥүр',
  'домох',
  'итирт',
  'кытах',
  'сүгүн',
  'кырыс',
  'тиэрт',
  'хамса',
  'эргэр',
  'ыраах',
  'хатар',
  'үктэн',
  'сөҥүү',
  'сыһын',
  'аатыр',
  'байыы',
  'кучан',
  'айгыр',
  'эрчий',
  'тэрил',
  'ислам',
  'моҕой',
  'бодоҥ',
  'оксид',
  'алҕаа',
  'полка',
  'хомуй',
  'хоһуй',
  'дьиэл',
  'кыһан',
  'бөтүү',
  'кудан',
  'кэбис',
  'иэмэх',
  'канал',
  'кыырт',
  'саала',
  'адаҕа',
  'мэнэх',
  'арааб',
  'тоҥот',
  'көһүй',
  'улаат',
  'иргэх',
  'кырый',
  'наука',
  'олорт',
  'тэнит',
  'харай',
  'кумык',
  'куруҥ',
  'укуол',
  'көлөн',
  'дьаба',
  'күрээ',
  'буоһа',
  'эмтэн',
  'уоруу',
  'сойут',
  'батар',
  'саайт',
  'куула',
  'өйдөт',
  'көҥүс',
  'бүтэр',
  'күрбэ',
  'ириэр',
  'эттик',
  'мэйин',
  'толор',
  'кудай',
  'майор',
  'сиидэ',
  'килэҥ',
  'дьаам',
  'панно',
  'уопут',
  'охсус',
  'тумат',
  'кобра',
  'солун',
  'народ',
  'индий',
  'барыы',
  'соҕох',
  'тэйии',
  'кылыы',
  'халый',
  'түһээ',
  'ордуу',
  'урсун',
  'көрүс',
  'куурс',
  'эрбэх',
  'хоруй',
  'бадах',
  'ууруу',
  'үрдэт',
  'кидан',
  'төгүл',
  'астаа',
  'дүлүҥ',
  'учуот',
  'аахай',
  'хотот',
  'обуос',
  'буһук',
  'очуос',
  'бутуй',
  'кулут',
  'дурба',
  'халаа',
  'үүтах',
  'тилин',
  'индус',
  'уйгур',
  'болот',
  'латын',
  'иһэҥэ',
  'такай',
  'далыс',
  'аабый', // Медведь
  'аамай', // Беспорядочность,суматоха, суета
  'аарка', // Арка
  'айбыт', // Творец, создатель (обычно вверованиях — о боге-творце, частоирон
  'албах', // Зараза; заразная болезнь
  'аҥхай', // Яма, углубление, трещина наповерхности земли
  'анамы', // Большой,крупный олень или лось
  'антыы', // Растение, рас-тущее на склоне горы
  'араан', // Место, накотором стоит чум, палатка; место стоян-ки, ночлега
  'араас', // Один (всчете чего-л)
  'ахсыа', // Восьмеро, восьмером
  'баачы', // Ши-рокий пояс на живот и спину (обычноиз беличьей, заячьей шкуры или ваты),набрюшник
  'баҕах', // Подруб-ленный кругом в нескольких местах столбтрехметровой высоты, украшенный узор-чатыми карнизами (предназначенный дляпроведения церемоний, различных обря-дов)
  'баҕыс', // Лодыжка, щиколотка
  'байба', // Деревян-ные тиски для сжимания склеиваемых досок (столярный инструмент)
  'балах', // Что-л
  'барык', // Непроглядная тьма
  'баһыр', // Сет-чатый черпак из конского волоса, ис-пользуемый для процеживания какой-л
  'битии', // Энер-гичная пляска на одном месте
  'биэги', // Сомнение, колебание
  'бойдо', // Замораживаниеводы на поверхности полозьев саней
  'болох', // Торчащая из-подольда вымерзшая трава
  'борук', // Сумрачный свет, полумрак
  'боһол', // Какая-л
  'бохто', // Перехват на чем-л
  'бөртө', // Черная полоска на передних лапах доколен и выше у отдельных экземпляровтаежных волков (у такого волка остьшерсти везде имеет красноватый иличерный оттенок, благодаря чему мехособо ценится)
  'бөрүө', // Стальная пластинка дляписания чернилами, перо
  'будка', // Будка
  'будул', // Какая-л
  'буочу', // Внутрен-ности забитой скотины, плотно заверну-тые в брюшину или приготовленные длятакого хранения
  'бутук', // Красная расти-тельная краска домашнего приготовления(напр
  'буута', // Путы, которыми связывают ногилошади перед пуском ее на пастьбу
  'быака', // Месяц, луна
  'быһый', // Передняя нога зайца
  'бэгэл', // Малорослая сосна, с вершины до корнейобросшая ветвями
  'бэргэ', // Застарелая опухоль; след, рубец отглубокой раны (к-рый постоянно поба-ливает)
  'бэски', // Чуб, челка
  'бэһис', // Пятеро, впятером
  'бэчик', // Молодая лист-венница
  'далан', // Поверхностьводы (озера, реки, моря), водная гладь
  'далла', // Чучело дляотпугивания птиц, пугало
  'далыр', // Плотина для вершей,устанавливаемая в трясине между двумяозерами
  'доҕуу', // Зубец, мелкая зазубрина; ост-рый выступ на чем-л
  'духуу', // Духи'
  'идэҥэ', // В старину: подношение, приношениевнутренностей скота духу перевала (сцелью задобрить его)
  'иккиэ', // Двое, вдвоем
  'илбис', // Бог вой-ны, единственный бог, имеющий однудочь (Илбис кыыһа) и одного сына(Оһол уола); дух кровопролития, вой-ны, раздора (в древности, готовясь квойне или сражению, якуты просилишамана, чтобы он вселил в оружиеилбис, для чего шаман обращался спризывом и мольбой к богу Илбису иего дочери Илбис кыыһа)
  'итиик', // Сыпь
  'килэс', // Бечева у нево- да и сети, свитая из тальниковой коры
  'көпөө', // Оленья шу-ба мехом внутрь
  'кууба', // Губа (залив северных морей)
  'күрэй', // Шест для управления оленями, хорей
  'кыаһы', // Веревка, ремень, которыми связывают ноги человеку или домашнему скоту, ограничивая свободу передвижения, путы
  'кыйма', // Кишки некоторых жи-вотных
  'кылас', // Пронзительный крик, вой
  'кылыа', // Волчий зуб — зуб, выросший более длинным, чем другие, или растущий вбок
  'кылын', // Суровость, строгость
  'кытык', // Причастность к чему-л
  'кытыт', // Молодая кобылица (двух-трех лет)
  'кычым', // Тебенок, тебеньки седельные (кожа-ные лопасти по бокам седла)
  'кыыча', // Кот, кошка
  'кэбир', // Препятствие, преграда
  'кэбэл', // Чудовище, окаянный
  'кэннэ', // В форме дательно-местного падежа: употребляется при обозначении предмета, позади (сзади) которого совершается дей-ствие, находится другой предмет (за, по-зади)
  'кэһэх', // Колчан
  'кэчир', // Мелкий карасик
  'лааба', // Расплавленная минеральная масса, выбрасываемая вулканом на поверхность земли, лава
  'лаабы', // Вывороченные с корнями, комьями земли большие деревья; пни
  'лаамы', // Море
  'лааха', // Редкая мелкая речная рыба с боль-шой головой, подкаменщик сибирский (местное название широколобка)
  'лабах', // Сильная за-грязнённость, замызганность чего-л
  'лаҥкы', // Дощечка с отверстием для верхнего конца палки, с помощью которой крутят руч ной жёрнов
  'лаҥха', // Полёг-шая под нынешним травостоем прошло-годняя трава на лугу
  'лапса', // Лапша
  'лапта', // Лапта (игра)
  'ломпо', // Шкурка с завязками для защиты от холода тыльной стороны кисти руки
  'лоҥку', // Дополнительный деревянный поплавок на крыле невода, используемый во время весеннего подлёдного лова
  'лөкөй', // Матё-рый самец лося
  'луҥту', // Кедр; лес из кедро-вых деревьев
  'луоҕа', // Глубокое болото, топь
  'луупа', // Лупа
  'лүҥкэ', // Таёжное озеро, глубокое от самых берегов
  'лыыба', // Озёрный гольян осеннего улова, которым запасают-ся впрок, заквашивая его в специальных коробах (употребляли в пищу зимой, после замерзания)
  'лэбэй', // Надоедливое повторение в речи одно-го и того же или многословное, долгое объяснение чего-л
  'лээби', // Надоедливое повторение в речи одно-го и того же, многословная болтовня
  'маама', // Мама (обращение к матери)
  'маары', // Способ ловить карасей, загоняя их с лодки в только что расстав-ленные сети
  'майба', // Морская рыба из се-мейства лососёвых, майма
  'маҥай', // Самая малость, без-делица
  'маҥкы', // Рукоятка рыбо-ловного сака
  'марба', // Кожаная сумка
  'мачай', // Излишняя, ненуж-ная суета, хлопоты
  'миилэ', // Десна, дёсны
  'мичил', // Радостная улыбка
  'миэнэ', // Мой, моя, моё
  'миэрэ', // Мера наказания
  'моҕох', // Двойной подбородок
  'молуу', // Почётное место напротив входа
  'монук', // Оленье молоко, заправленное голубикой
  'моруу', // Мучение, страдание
  'моһол', // Затруднение, препятствие, преграда на пути чего-л
  'мочоҥ', // Занос на дороге, наметённый вьюгой снежный сугроб, преграждающий путь
  'мөлбө', // Полный, пышный, пушистый, округлой формы
  'мөҥүн', // Серебро
  'мөҥүө', // Створка, за-крывающая отверстие в плотинах
  'мыкса', // Чум-ка собачья
  'мэкчэ', // Короткое пальто из шкуры летнего оленя, исполь-зуемое в хозяйственных работах
  'мэҥиэ', // Приманка, при-кормка, наживка (для птиц, зверей, рыб)
  'мэҥэл', // Перекладина для сушки или раз-вешивания чего-л
  'мээми', // Отдельная прорубь овальной фор мы, рядом с главной, в которую за-кидывают невод с выловленной рыбой
  'мээмэ', // Приспособление для кормления младенца молоком, соска
  'налба', // Щит из ивовых прутьев или молодых лиственниц, служащий рыбо-ловным заграждением
  'намыс', // Большой, глубокий снег
  'нарыа', // Пологий склон холма, горы
  'нойон', // Обращение к более молодо - му человеку: молодой человек, парень
  'нокоо', // Жирная часть тела у основания хвоста (о крупном рогатом скоте)
  'нохто', // Вена, аорта
  'нөмөй', // Нижняя часть грудинки (у ско-та)
  'нөөкү', // Деревянная постройка (кладовка на ножках) или яма для хра-нения продуктов, вещей
  'нуота', // Но та
  'нырыы', // Длин-ный берёзовый шест, который во время осеннего подлёдного лова опускают в прорубь и бьют им по дну озера, чтобы загнать рыбу в сеть
  'нээги', // Шерсть с измятой остью, свалявшаяся шерсть
  'обоко', // Курительная трубка примитивной работы
  'обуой', // Обои
  'оҕуур', // Шест с верёвочной пет-лёй на конце, аркан
  'ойдуо', // Якутский музыкальный деревянно-духовой инструмент, похожий ОЙДУО234на горн (с резным узором, длиной чуть больше метра, расширяющийся к кон-цу)
  'омнуо', // Предосудительный поступок; недоста-ток
  'омоон', // Слабое очертание предмета, нечёткий силуэт
  'оодул', // Мелкотолчёная и просеянная сосновая заболонь, мука из сосновой заболони или сусака
  'оокко', // Ласковое обращение к ребёнку: детка, деточка, дитятко (обычно с притяж
  'орбоҥ', // Внешний половой орган у самки крупного рогатого скота
  'оргул', // Небольшое возвышение, не затоплен-ное водой (во время весеннего разлива)
  'ордоо', // Зычный крик, ор
  'ороон', // Чужая сторона, страна
  'ороох', // Самодельная курительная трубка без мундштука
  'өгдүө', // Податливость, покорность, безропот-ность
  'өйөмө', // Глубокие места мелководных озёр, где зимуют ка-раси (в таких местах весной карасей ловят сачком — куйуур)
  'өйөмү', // Пузыр-чатый осенний лёд в озёрах, который ещё недостаточно окреп
  'өллөй', // Спасение, защита, избавление от чего-л
  'өнчөх', // Спинка (напр
  'өргөс', // Острый конец, остриё скотского рога
  'өркөн', // Прядь солнечных лучей (пронзившая облака)
  'өрөһө', // Куча чего-л
  'өртөх', // Прут
  'өртүк', // Огороженное пастбище, ого-роженный выгон для домашнего скота (близ жилья)
  'өрүөл', // Изоб-ражение орла (на монетах, печатях)
  'өтөҕө', // Кучка конского помёта
  'саарт', // Шарф
  'сабар', // Пригоршня
  'салар', // Умение, мастерство, сноровка
  'салгы', // Что­л
  'салыҥ', // Выделяемая некоторыми животными и растениями тягучая скользкая масса, слизь
  'самах', // Про-межность
  'самыы', // Нижняя (у животных зад­няя) часть спины от поясницы до ягодиц (у животных до задних выступов та­зовой кости), зад, круп (у лошади)
  'санда', // Разновидность рыболовного заездка
  'саргы', // Счастливое будущее
  'сарпа', // Густые лучи, искры, идущие от сильного источника света (напр
  'сарык', // Пламя, огонь
  'сибиэ', // В старину: своеобразный щит из связанных между собой с промежутками тонких палочек или тальника, который использовался как настил для сушки на солнце мелкой рыбы или служил стенкой для рыболовного заграждения
  'сиилэ', // Способность трудиться, сила, энергия (физическая, мускульная — употр
  'силик', // Выс-шая степень расцвета чего­л
  'сирин', // Вы-мя
  'сирэм', // Мо-лодая трава, появляющаяся весной на по-лях, зелёный покров земли
  'сиэги', // Длинная прочная во-лосяная верёвка, бечева
  'соҕуо', // Зоб (болезнь)
  'сонор', // Преследование зверя (обычно пушно­го) по первому осеннему снегу
  'сорох', // Не все (не всё), часть кого­чего­л
  'сорсу', // Первые всходы зелёной травы после таяния снега весной
  'сотук', // Мозаичная расшивка из лос-кутков разных шкур
  'сохсо', // Ло-вушка, пасть (на зверя)
  'соччо', // Столько же, столько
  'сөҥөл', // Осадок, отстой чего-л
  'сөрүө', // Плотное плетё-ное изделие из конских волос или камы-ша, используемое как подстилка на нары, половик или как потник под седло
  'субай', // Отстоявшаяся в посу-де алая жидкая конская кровь без сгуст-ков; кровяная колбаса из такой крови
  'субуу', // Вытянутая в длину полоса, гряда скошенной травы, валок
  'судьу', // Луток малый
  'сукуй', // Объёмная доха без застёжек, надевае-мая снизу через прорезь горловины, кух-113СУКУҤНАСлянка
  'сулар', // Не-доуздок
  'сулук', // Узкая нашивка на задник обуви
  'сулуу', // Калым в виде скота
  'суҥха', // Выпячивание своих заслуг с расчётом на вознаграждение
  'суода', // Сода
  'суорт', // Категория, разряд какого-л
  'суһум', // Мер-цание, сияние чего-л
  'сутул', // Беда, напасть
  'сууда', // Ссуда (денежная или вещевая)
  'сүлбэ', // Рус-ло, фарватер реки
  'сүптү', // Досада, горечь
  'сүттү', // Чувство раздражения, недовольства, сильной досады
  'сыбар', // Непроходимая чаща
  'сыган', // Дети, по-томки кровных родственников (напр
  'сылам', // Тепло, которое согревает кого-что-л
  'сылба', // Остаток сил утомлённого, изнурённого чем-л
  'сылык', // Предположение, прогноз на основе ка-ких-л
  'сымыс', // Пах (человека или животного)
  'сыппа', // Ремешки, которыми под-вязывают наколенники (сутуруо) к натаз-никам (сыалдьа)
  'сырал', // Тепло, жар от какого-л
  'сыркы', // Источник, родник, ключ или малень-кий ручеёк
  'сырпы', // Шурф
  'сыһык', // Запасы мяса, внут-490СЫҺЫКТЫЙренностей, вложенные в конский или скотский желудок и оставляемые впрок
  'сэдип', // Примета, знак, по которым можно узнать, определить что-л
  'сэксэ', // Подстилка из ветвей (берёзовых или лиственничных деревьев)
  'сэмсэ', // Добавка, прибавка к чему-л
  'сэтир', // Обре-занные ветви деревьев или разрубленный тальник; какое-л
  'таала', // Равнина с твёрдой сухой поверхностью
  'таҕыл', // Стремление к празд-ному, беззаботному времяпрепровожде-нию
  'тайма', // Музыкальный инструмент в виде ряда железных или медных пластинок разного размера, помещённых в длинную узкую коробку, по которым ударяют дву-мя палками с набалдашниками, извлекая звуки различной высоты
  'такым', // Коленный сгиб, подколенок
  'талҕа', // Разбивка (на части), разбрасывание
  'талкы', // Деревянный инструмент с зубьями для смягчения кожи, якутская кожемялка
  'таман', // Тонкий наруж-ный слой мяса на рёбрах, а также жир под ним у скота и зверя
  'тамма', // Сбитый в комок снег для получения питьевой воды
  'тамык', // Подколенка, задняя сторона колен
  'таныы', // Ноздря, ноздри
  'таппы', // Медвежья шкура
  'таспа', // Наружное покрытие (урасы, лука)
  'таһаа', // Бедренная часть туловища, ляжка человека или зад-няя часть туловища, круп лощади и вид её сзади; нижняя бедренная часть одеж-ды
  'тибии', // Снежная пурга, вьюга, буря
  'тиник', // Остатки мяса на костях, которые можно обглодать
  'тиргэ', // Силки, петли из конского волоса на водоплавающую дичь
  'тириэ', // Тире
  'тоҕус', // Девять
  'тойуу', // Кусок чего-л
  'токоо', // Палка с крюком, служащая для соединения силка с рычагом
  'тонох', // Говяжий сбой (голова, ноги и внут-ренности убитого на мясо животного)
  'торум', // Контур, очерта-ния чего-л
  'тохоо', // Деталь деревянной сохи, к которой прикрепляется планка с дугообразным концом, используемая для регулировки глубины погружения лемеха
  'тугул', // Внутренняя часть когтя, копыта или рога скота, жи-вотного
  'тулум', // Остаток чего-л
  'тумук', // Сосок (у жен-щин, самок млекопитающих)
  'тураҥ', // Засоленная почва, солончак
  'турку', // Нарты
  'туура', // Узкая часть какого-л
  'түлүк', // Темнота, мрак; тёмное время
  'түһэх', // Передняя часть ноги выше колена (у человека)
  'тыгын', // Высокий чин, господин, правитель, предводитель (со значением «великий, почтенный»)
  'тэлим', // Колыхание, волнение, трепет чего-л
  'тэрэҥ', // Жаркое из мед-вежьих потрохов
  'тээтэ', // Отец
  'угаар', // Удушливый газ, образую-щийся при неполном сгорании древесного угля, угарный газ
  'уксуу', // Ставка (в азартных играх)
  'уорҕа', // Спина, спинной хре-бет
  'уорук', // Боль-шой, просторный, хорошо обставленный дом (жильё, жилище)
  'уоһах', // Желтоватое и густое молоко коровы, кобылицы в первое время после родов, молозиво
  'уохта', // Смородина-дикуша, охта, алданский виноград
  'урдус', // Потомок, отпрыск
  'уруул', // Меха-низм для управления транспортом, руль
  'уһаат', // Небольшая кадка, ушат
  'уучах', // Верховой олень, олень для верховой езды
  'уххан', // Разгар, куль-минация чего-л
  'үймээ', // Гомон, гвалт
  'үллүк', // Что-л
  'үөдэн', // Нижний мир, преис-подняя, ад
  'үөлэс', // Ды-мовая труба камелька — якутской печи
  'үөрбэ', // Заострённый кол, шест, рожон (тонким заострённым колом, напр
  'үргэл', // Плеяды
  'үрүөх', // Изогнутый нож для соскабливания шерсти со шкуры или скобления мездры при выделке кожи
  'үскэй', // Остатки добы-чи хищников
  'үстүү', // По три раза, трижды
  'үһүөн', // Собравшись втроём, все трое
  'үүрбэ', // Гурт, стадо скота одного погонщика (обычно приданое)
  'хайыҥ', // Невысо-кая насыпь (напр
  'хайыр', // Гравий, галька, булыжник или груда камней
  'хайыы', // Органы дыхания рыб и некоторых других водных животных, жабры
  'халба', // Широко-носка (вид утки)
  'ханда', // Домовой, дух дома
  'ханна', // Вопросительное слово со значениями «где? в каком месте? куда?»
  'хаҥкы', // Коньки (для катания)
  'хаҥха', // Вьючное седло
  'харас', // Черёмуха
  'харгы', // Неглубокое место в реке, реч-ке с быстрым течением, мель, отмель
  'хаһаа', // Конюшня, стойло для лошадей и жеребят
  'ходуу', // Косьба травы на корню по льду поздней осенью; скошенная по льду поздней осенью трава
  'хоппо', // Небольшой деревян-ный или картонный ящик, служащий для хранения, упаковки чего-л
  'хорой', // Двухгодовалый оленё-нок-самец
  'чалкы', // Водянистые образования, зернистость на веках глаз
  'чарай', // Плодный пузырь (у животных)
  'чарпа', // Небольшие части чего-л
  'чигди', // Утоп-танный, затвердевший снег
  'чинчи', // Признак, примета (по которым можно узнать, определить что-л
  'чомой', // Помощник, подручный (обычно о ре-бёнке, молодом человеке)
  'чоҥку', // Берцовая кость (обычно оленя, лося), костный мозг из которой употребляется в сыром виде как лакомство
  'чопчу', // Сосок (у женщин, самок млекопитающих)
  'чохоо', // Маленькая деревянная чашеч-ка
  'чочур', // Сопка
  'чугуу', // Большой длинный прыжок
  'чумаа', // Острое инфекционное заболевание, смертельно опасная болезнь, чума
  'чырып', // Один из видов куликов: плавунчик круглоно-сый
  'чыскы', // Металлические щипцы, инструмент для захвата, зажима чего-л
  'чэмэй', // Низкий, подлый чело-век
  'чэркэ', // Мелкий карась
  'чээрэ', // Чёрный гриб в виде на-роста на стволе берёзы, чага
  'ыаһах', // Разговоры, болтовня, на которые впустую тратится время
  'ыҕайа', // Штевень, брусья, представ-ляющие продолжение киля у корабля, большой лодки
  'ылбай', // Малёк, молодь (рыбы)
  'ымпык', // Какая-л
  'ынчык', // Стон, сте-нания
  'ыҥаах', // Оленёнок, лосёнок до года
  'ыпсыы', // Место соединения, сцепления чего-л
  'ытаһа', // Дере-вянный или железный клин для закреп-ления чего-л
  'ытыыс', // Сруб погреба
  'ыыспа', // Маленькая, низкая юрта-балаган с печкой-камельком
  'эбиэн', // Ост-рый сердцевидный наконечник стрелы, самострела или черкана
  'эмэкэ', // Рожок с соской, из кото-рого кормят младенца молоком
  'эмэһэ', // Задняя часть тела человека ниже спины, задни-ца, зад; задняя часть туловища у живот-ных
  'эмээн', // Прок, толк, польза
  'эндир', // Поперечная подпорка, чурка, ко-торая кладётся подо что-л
  'эндиэ', // Ошибка, недочёт, недостаток
  'эндэл', // Опрометчивость, ошибка; просту-пок
  'эҥийэ', // Покатость, скат, склон возвышенной местности
  'эҥкил', // Не-достаток, изъян, отрицательная черта; ошибка, недочёт, промах кого-л
  'эҥээн', // У ро-гатого скота, лося, оленя: надкопытные роговые наросты (издающие при ходьбе характерный звук)
  'эҥээр', // Передняя левая или правая пола одежды; нижняя пола одежды
  'эпиир', // Эфир ЭП II261(как органическое соединение)
  'эрэһэ', // Большое сверло
  'эһиги', // Вы (личное мест
  'этээп', // Отдельный момент, стадия какого-л
  'ээйтэ', // Росомаха
]
