export const WORDS = [
  'төрүт',
  'солко',
  'эрээт',
  'үктэл',
  'турук',
  'мутук',
  'тимэх',
  'чэчик',
  'өһүөн',
  'көтөл',
  'кытыы',
  'кырык',
  'тулук',
  'хаһыы',
  'ыарҕа',
  'арбай',
  'харыс',
  'тойук',
  'айхал',
  'таһым',
  'тиэмэ',
  'кыспа',
  'хахай',
  'ардай',
  'сурах',
  'симэх',
  'оруос',
  'асчыт',
  'архах',
  'эстии',
  'силис',
  'толон',
  'сэрии',
  'курум',
  'номох',
  'аймах',
  'ардьа',
  'бытык',
  'балык',
  'ирээт',
  'үрүмэ',
  'күөрт',
  'ордук',
  'күрэс',
  'сайын',
  'талба',
  'эрдии',
  'талыы',
  'кырыа',
  'кэрий',
  'мэйии',
  'оҥочо',
  'саҕах',
  'сарай',
  'адьас',
  'кыһыы',
  'сылаа',
  'иитии',
  'тунах',
  'батас',
  'үҥкүү',
  'туһах',
  'кырыы',
  'түһүк',
  'тумуу',
  'киэһэ',
  'куйах',
  'куолу',
  'силим',
  'дьана',
  'бэдэр',
  'аньыы',
  'сорук',
  'мунду',
  'уолан',
  'көлгө',
  'хатыҥ',
  'бугул',
  'муҥур',
  'кыйыа',
  'сүрэх',
  'ситим',
  'өтүйэ',
  'түмэл',
  'бырта',
  'эбиэт',
  'хотой',
  'бэлэс',
  'баарт',
  'бырыы',
  'сэниэ',
  'күрүө',
  'билгэ',
  'сүөһү',
  'эмчит',
  'ымсыы',
  'алаас',
  'быйаҥ',
  'тумус',
  'тиһэх',
  'алгыс',
  'түгэн',
  'күһүн',
  'күлүк',
  'араҥа',
  'иэйии',
  'хонук',
  'үтэһэ',
  'салаа',
  'тутуу',
  'саһыл',
  'тирэх',
  'төрүк',
  'хабах',
  'сурук',
  'сиэмэ',
  'хотон',
  'эрбии',
  'дууһа',
  'чараҥ',
  'түмүк',
  'ыччат',
  'марай',
  'түбүк',
  'сырыы',
  'төлкө',
  'ыарыы',
  'сэһэн',
  'сырай',
  'арҕах',
  'күөгү',
  'үөрэх',
  'арҕас',
  'дугда',
  'кыһын',
  'таҥас',
  'харчы',
  'түгэх',
  'күнүү',
  'сыһыы',
  'идэһэ',
  'тутум',
  'кытыл',
  'санаа',
  'харах',
  'тайах',
  'охчут',
  'иҥиир',
  'улуус',
  'мэһэй',
  'көмүс',
  'тумул',
  'ньыма',
  'көрүҥ',
  'буруо',
  'албас',
  'ууһут',
  'ойуун',
  'үрүйэ',
  'эмиий',
  'туйах',
  'өттүк',
  'баккы',
  'сөтөл',
  'дулҕа',
  'тирии',
  'алтан',
  'хомус',
  'талах',
  'уҥуох',
  'чагда',
  'аргыс',
  'сирэй',
  'кумах',
  'ыксал',
  'албын',
  'сыыһа',
  'бөлөх',
  'кынат',
  'быһыы',
  'тэтиҥ',
  'күүлэ',
  'хатыс',
  'буруй',
  'арҕаа',
  'тоҥус',
  'ыһыах',
  'тойон',
  'хопто',
  'бичик',
  'дойду',
  'билии',
  'доҕор',
  'содул',
  'тобук',
  'өрбөх',
  'биһик',
  'сулус',
  'кэтэх',
  'ураһа',
  'бэлиэ',
  'күлүү',
  'күрэх',
  'сонун',
  'сэргэ',
  'мурун',
  'хатта',
  'майгы',
  'үөрүү',
  'быһах',
  'хонуу',
  'кыбыы',
  'эркин',
  'абаҕа',
  'былыт',
  'көҥүл',
  'игирэ',
  'оһуор',
  'тобох',
  'кымыс',
  'ыллык',
  'өбүгэ',
  'тайҕа',
  'алаһа',
  'хотун',
  'тобул',
  'үрдэл',
  'байыт',
  'кэһии',
  'оруол',
  'атыыр',
  'муҥха',
  'ардах',
  'тимир',
  'туоһу',
  'дурда',
  'ойуур',
  'тымыр',
  'таҥха',
  'оттук',
  'амтан',
  'отчут',
  'ахтыы',
  'бэлэх',
  'сылгы',
  'хоруо',
  'ыйаах',
  'ойбон',
  'тилэх',
  'күнүс',
  'лабаа',
  'иэдэс',
  'ооҕуй',
  'ириҥэ',
  'дьаат',
  'уолба',
  'силии',
  'алҕас',
  'дагда',
  'сэрэх',
  'мичик', // Лёгкая улыбка, выражающая радость, удовольствие
  'сэгэр', // Близкий человек, с которым связыва-ет дружба, любовь
  'хаһыҥ', // Лёгкий утренний мороз осенью, весной, заморозки
  'липпэ', // Скопление перегнивших водорослей, мхов на дне озера; озёрный торф
  'саҥас', // Жена старшего род-ного брата или старшего родственника (для лиц обоего пола)
  'туску', // Благо, благополучие, светлое будущее (человека, народа)
  'сугун', // Голубика
  'харта', // Толстая кишка (у лошади); блюдо, приготовленное из кон-ской толстой кишки — одно из излюб-ленных блюд якутов
  'сүтүк', // Потеря, ущерб, убыток
]
